import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { getInjector } from '.';
import { AuthService, HttpService } from '@wearewarp/ng-web';
import { Utils } from './utils';
import { Const } from '@const/Const';

@Injectable()
export class ApiService extends HttpService {

  constructor(
    protected client: HttpClient,
    protected auth: AuthService,
  ) {
    super(client);
  }

  public static get instance(): ApiService {
    let injector = getInjector();
    return injector.get(ApiService);
  }

  // Các trường imageUrl api trả về chỉ là đường dẫn tương đối
  // Dùng hàm này để tạo thành URL hoàn chỉnh của image rồi mới hiển thi được
  // Image thì gọi vào server image
  public static getImageUrl(relativePath) {
    if (!relativePath) {
      return '';
    }
    if (Utils.isUrl(relativePath)) {
      return relativePath;
    }
    let _relativePath = relativePath.trim().replace(/\s+/g, '%20');
    return '/' + _relativePath;
  }

  // mặc định sẽ gọi về server-workqueue
  public buildUrl(path: string, version: string = 'v1'): string {
    if (/^https?:\/\//.test(path)) return path;
    if (/^http?:\/\//.test(path)) return path;
    if (/^v[0-9]+\//.test(path)) {
      return `${environment.backendUrl}/${path}`; // already included version in path
    }
    return `${environment.backendUrl}/${version}/${path}`;
  }

  // Dùng khi muốn custom origin khi build url
  public buildUrlWithOrigin(origin, path: string, version: string = 'v1'): string {
    if (/^https?:\/\//.test(path)) return this.buildUrl(path);
    if (/^http?:\/\//.test(path)) return this.buildUrl(path);
    if (/^v[0-9]+\//.test(path)) {
      return this.buildUrl(`${origin}/${path}`);
    }
    return this.buildUrl(`${origin}/${version}/${path}`);
  }

  download(url: string) {
    let _url = this.buildUrl(url);
    return this.GET(_url, { responseType: 'arraybuffer' });
  }

  logout() {
    this.auth.logout();
  }

  public searchUsCities(keyword: string) {
    let url = `${Const.API_SEARCH_US_CITIES}?q=${encodeURIComponent(keyword)}`;
    return this.GET(url);
  }
}