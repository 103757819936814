import { map, of } from "rxjs";
import { ApiService } from "./api.service";
import { Const } from "@const/Const";
import { Log } from "./log";
import { DateUtil } from "./date-util";

export interface MasterWorkQueue {
  timezones: any[];
  mapBoxAccessToken?: string,
  countries: any[],
  countries_states: any,
}

export class MasterData {
  private static data: MasterWorkQueue = <any>{};
  private static mapCountries = {};  // map by _id
  private static countryUS: any = {};
  private static canadaProvinces: any = {};

  public static get mapboxToken() {
    return this.data.mapBoxAccessToken;
  }

  public static set(data: MasterWorkQueue) {
    this.data = Object.assign(this.data || {}, data);
    if (data?.timezones) {
      DateUtil.listTimezones = data.timezones;
    }
    if (data?.countries) {
      for (let i = 0; i < data.countries.length; i++) {
        this.mapCountries[data.countries[i]._id] = data.countries[i];
        if (data.countries[i].alpha2Code == 'US') {
          this.countryUS = data.countries[i];
        }
      }
    }
    if (data?.countries_states?.canada_provinces){
      this.canadaProvinces = data?.countries_states?.canada_provinces
    }
  }

  public static getStatesUS(): Array<any> {
    return this.countryUS.states || [];
  }

  public static getCanadaProvinces(): Array<any> {
    return this.canadaProvinces;
  }

  public static getStateUSByCode(code: string) {
    for (let item of this.countryUS.states) {
      if (item.code == code) {
        return item;
      }
    }
    return null;
  }
}