import { OnInit, OnDestroy, AfterViewInit, OnChanges, DoCheck, AfterContentInit, AfterContentChecked, AfterViewChecked, Injector, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { getInjector, getApp, App, getManagerDashboard } from '@services/index';
import { Subscription } from 'rxjs';
import { ApiService } from '@services/api.service';
import { Log } from '@services/log';
import { Utils } from '@services/utils';
import { AuthService, EventAuthUserChange } from '@wearewarp/ng-web';
import { WorkOperatorAuthUser } from '@app/interfaces';
import { Const } from '@const/Const';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

@Directive()
export class BaseComponent implements OnChanges, OnInit, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {

  get componentUrl(): string { return window.location.href.split('?')[0]; }
  protected get authUser(): WorkOperatorAuthUser { return this.appComponent.getAuthUser() }

  protected TAG;
  private windowResizeSubscription: Subscription;
  private authChangeSubscription: Subscription;

  private _queryParams;
  protected get queryParams() { return this._queryParams || {} }

  protected injector: Injector;
  protected router: Router;
  protected api: ApiService;
  protected auth: AuthService;
  protected subscription: Subscription = new Subscription();

  constructor(protected activatedRoute: ActivatedRoute = null) {
    this.TAG = this.constructor.name;
    this.injector = getInjector();
    this.router = this.injector.get(Router);
    this.auth = this.injector.get(AuthService);
    this.api = this.injector.get(ApiService);
    if (this.auth) {
      this.authChangeSubscription = this.auth.authChanged.subscribe(ev => {
        switch (ev.code) {
          case EventAuthUserChange.login:
            return this.didLogin();
          case EventAuthUserChange.logout:
            return this.didLogout();
          default: return;
        }
      });
    }
    if (this.appComponent) {
      this.windowResizeSubscription = this.appComponent.subscribeWindowResize(ev => this.onWindowResize(ev));
    }
  }

  ngOnChanges() {
  }

  ngOnInit() {
  }

  ngDoCheck() {
  }

  ngAfterContentInit() {
  }

  ngAfterContentChecked() {
  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
  }

  ngOnDestroy() {
    this.windowResizeSubscription?.unsubscribe();
    this.authChangeSubscription?.unsubscribe();
    this.subscription?.unsubscribe()
  }
  protected onWindowResize(event: Event) {
  }

  protected didLogin() {
  }
  protected didLogout() {
  }

  protected get appComponent(): App { return getApp(); }

  public get currentUrl(): string {
    return this.router.url;
  }

  public get isLoggedIn(): boolean {
    return this.auth.alreadyLoggedIn();
  }

  public onNavigationEnd(url: string): void {
    Log.d(`[${this.TAG}] onNavigationEnd: ${url}`);
    let params = Utils.parseQueryStringFromUrl(url);
    let prevQueryParam = this.queryParams || {};
    this._queryParams = Utils.cloneObject(params, true);
    this.handleNavigationEnd(url, prevQueryParam);
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
  }

  // query: chỉ cần chứa những param mới cần thay đổi, sau đó sẽ được merge vào queryParams cũ.
  // Dùng hàm này khi url path ko đổi, chỉ thay đổi query param
  // replace = true: repace all query params instead of merge
  protected routeWithQueryUrl(query, replace = false) {
    let url = this.router.url.split('?')[0];
    let q = replace ? query : Object.assign(Utils.cloneObject(this.queryParams || {}), query);
    this.router.navigate([url], { queryParams: q });
  }

  protected goLogin() {
    this.router.navigate([Const.routeLogin]);
  }

  protected goDashboard() {
    this.router.navigate([Const.routeDashboard]);
  }

  protected autoShrinkSideBar() {
    setTimeout(() => getManagerDashboard().sideBar.isSmallWidth = true, 1);
  }
}