import { AddressUS } from "@wearewarp/types/data-model";
import { MasterData } from "./master.data";

export class Utils {

  public static getErrorString(err) {
    if (!err) {
      return '';
    }
    if (typeof err === 'string') {
      return err;
    }
    if (err.constructor.name === 'Response') {
      err = err.json();
    }
    if (err.ui_message) {
      return err.ui_message;
    } else if (err.message) {
      return err.message;
    } else {
      try {
        return JSON.stringify(err);
      } catch (e) {
        return err;
      }
    }
  }

  public static getSuccessString(resp) {
    return this.getErrorString(resp);
  }

  // url: http://nt.com?name=NT&email=nt@gmail.com
  public static parseQueryStringFromUrl(url) {
    let isString = typeof url === 'string' || url instanceof String;
    if (!isString) {
      return {};
    }
    let arr = url.split('?');
    if (arr.length < 2) {
      return {};
    }
    return this.parseQueryString(arr[1]);
  }
  
  // query: name=NT&email=nt@gmail.com
  public static parseQueryString(query: string) {
    return JSON.parse('{"' + query.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
  }

  // compareFn returns true means a & b are the same
  public static isArraysTheSame(arr1, arr2, compareFn?: (a, b) => boolean) {
    let len1 = arr1 ? arr1.length : 0;
    let len2 = arr2 ? arr2.length : 0;
    if (!arr1 && !arr2) {
      return true;
    }
    if (!arr1 && arr2 && len2 > 0) {
      return false;
    }
    if (!arr2 && arr1 && len1 > 0) {
      return false;
    }
    if (len1 != len2) {
      return false;
    }
    for (let i = 0; i < len1; i++) {
      let equal = compareFn ? compareFn(arr1[i], arr2[i]) : arr1[i] == arr2[i];
      if (!equal) return false;
    }
    return true;
  }
  
  // neverNull: to make sure return object will not be null or undefined
  public static cloneObject(obj, neverNull = false) {
    if (obj === null || obj === undefined) return neverNull ? {} : obj;
    return JSON.parse(JSON.stringify(obj));
  }

  // true: obj1 và obj2 are giống nhau, false: khác nhau
  // cùng null, cùng undefined: giống nhau
  // 1 trong 2 thằng ko phải object: khác nhau
  // ko áp dụng cho nested object
  // onlyFields: chỉ so sánh cho những trường này thôi, các trường khác thì bỏ qua
  public static compareObject(obj1, obj2, onlyFields: Array<string> = null): boolean {
    if (obj1 === null && obj2 === null) return true;
    if (obj1 === undefined && obj2 === undefined) return true;
    if (!this.isObject(obj1) || !this.isObject(obj2)) return false;
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    if (onlyFields && onlyFields.length > 0) {
      keys1 = keys1.filter(it => onlyFields.indexOf(it) >= 0);
      keys2 = keys2.filter(it => onlyFields.indexOf(it) >= 0);
    }
    if (keys1.length != keys2.length) return false;
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) return false;
    }
    return true;
  }

  public static isString(variable) {
    return typeof variable === 'string' || variable instanceof String;
  }
  
  public static isNumber(variable) {
    return typeof variable === 'number' || variable instanceof Number;
  }

  public static isBoolean(variable) {
    return typeof variable === 'boolean' || variable instanceof Boolean;
  }

  public static isArray(variable) {
    return Array.isArray(variable);
  }

  public static isArrayNotEmpty(variable) {
    return Array.isArray(variable) && variable.length > 0;
  }

  public static isFunction(variable) {
    return typeof variable === 'function' || variable instanceof Function;
  }

  public static isObject(variable) {
    return variable !== null && typeof variable === 'object';
  }

  public static isObjectNotEmpty(obj) {
    return this.isObject(obj) && Object.keys(obj).length > 0;
  }

  public static toNumber(variable, defaultValue: number): number {
    if (variable === null || variable === undefined) return defaultValue;
    if (isNaN(variable)) return defaultValue;
    if (this.isNumber(variable)) return variable;
    let n = Number(variable);
    return isNaN(n) ? defaultValue : n;
  }

  
  public static getResponsiveSize(width: number): ResponsiveSize {
    if (width < 576) {
      return 'xs';
    } else if (width >= 576 && width < 768) {
      return 'sm';
    } else if (width >= 768 && width < 992) {
      return 'md';
    } else if (width >= 992 && width < 1200) {
      return 'lg';
    } else if (width >= 1200 && width < 1600) {
      return 'xl';
    } else {
      return 'xxl';
    }
  }

  public static capitalize(str) {
    if (!str) return str
    return str.replace('_', ' ').split(' ').map(it => it.charAt(0).toUpperCase() + it.slice(1).toLowerCase()).join(' ')
  }

  public static getAddressStr(addr: AddressUS): string {
    if (!addr) return '';
    let str = '';
    for (let key of ['street', 'street2', 'city', 'state', 'zipcode']) {
      if (addr[key]) {
        if (str.length > 0) {
          str += ', ';
        }
        str += addr[key];
      }
    }
    return str;
  }

  public static copyTextToClipboard(text, callback) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text, callback);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      callback(null);
    }, function (err) {
      callback(err);
    });
  }

  public static fallbackCopyTextToClipboard(text, callback) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      callback(successful ? null : "document.execCommand('copy') failed.");
    } catch (err) {
      callback(err);
    }
    document.body.removeChild(textArea);
  }

  // remove duplicate elements in array
  public static uniqElementsArray<T>(arr: T[]) {
    return Array.from(new Set(arr));
  }

  public static isUrl(str: string) {
    return this.isString(str) && (str.startsWith('http://') || str.startsWith('https://'));
  }

  public static appendQueryStringIntoUrl(url: string, query: object) {
    let paramsStr = '';
    for (let key of Object.keys(query)) {
      if (paramsStr.length > 0) {
        paramsStr += '&';
      }
      paramsStr += `${key}=${encodeURIComponent(query[key])}`;
    }
    return url.includes('?') ? `${url}&${paramsStr}` : `${url}?${paramsStr}`;
  }

  public static getAvatarCharacter(fullName) {
    if (!fullName) return 'N/A';
    let items = fullName.split(' ');
    let lastName = items[items.length - 1];
    let firstC = lastName.slice(0, 1);
    return firstC.toUpperCase() || ''
  }

  public static getFullName(user: {fullName?: string, firstName?: string, lastName?: string}) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    return [user.firstName || '', user.lastName || ''].join(' ').trim();
  }

  public static getStateDesc(state): string {
    let obj = state;
    if (typeof state == 'string') {
      obj = MasterData.getStateUSByCode(state);
    }
    let str = obj.code;
    if (obj.name) {
      str += ` (${obj.name})`;
    }
    return str;
  }

  public static getDisplayOrdinalNum(idx: number) {
    if (!idx) return '';
    switch (idx) {
      case 1: return '1st';
      case 2: return '2nd';
      case 3: return '3rd';
      default: return `${idx}th`;
    }
  }
}
