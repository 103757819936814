import { Routes } from '@angular/router';
import { PublicComponent } from './public';
import { Login } from './auth/login';
import { VerifyLogin } from './auth/verify-login';

export const APP_ROUTES: Routes = [
  { path: '', component: PublicComponent,
    children: [
      { path: 'login', component: Login, data: { title: 'Login - WARP Work Manager'} },
      { path: 'verify', component: VerifyLogin, data: { title: 'Verify login - WARP Work Manager'} }    
    ]
  },
  { path: 'dashboard', loadChildren: () => import('./work-admin/dashboard.routing').then(m => m.DASHBOARD_ROUTES) },
  // { path: 'tasks', loadChildren: () => import('./work-tasks/dashboard.routing').then(m => m.DASHBOARD_ROUTES) },
  { path: '**', redirectTo: ''},
];
