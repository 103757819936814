import { Router } from "@angular/router";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { PermissionManager } from "./permission-manager";
import { UIHelper } from "./UIHelper";


export function verifyAfterLogin(authUser, router: Router): void {
  if (PermissionManager.allowLogin(authUser)) {
    router.navigate([Const.routeDashboard]);
    return;
  }
  let msg = 'You are not allowed to log in to Work Manager portal dashboard.';
  ApiService.instance.logout();
  UIHelper.showDialog(msg);
}

export function verifyPasswordExpired(authUser, router: Router) {
  if(router.url === '/password-expired') return;
  if (authUser?.passwordExpiryDate && new Date(authUser.passwordExpiryDate) < new Date()){
    window.location.href = `/password-expired`;
    return false
  }
  return true;
}

// Vì có trường hợp user đã login vào dashboard từ trước rồi
// Rồi mới bị thay đổi permission nên cần gọi hàm này để logout
export function verifyWhileUsingDashboard(authUser, router: Router): void {
  if (!PermissionManager.allowLogin(authUser)) {
    ApiService.instance.logout();
    router.navigate([Const.routeLogin]);
  }
}

export function requirePermissions(authUser, permissions): boolean {
  return PermissionManager.userHasOneOfPermissions(authUser, permissions);
}