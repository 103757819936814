import to from 'await-to-js';
import { Component } from "@angular/core";
import { BaseFormItem } from '@app/base-components/form-item';
import { InputHelper } from "@services/input-helper";
import { ActivatedRoute } from "@angular/router";
import { Log } from "@services/log";
import { verifyAfterLogin } from '@services/auth.check-role';
import { Crypto } from '@services/crypto';
import { lastValueFrom } from 'rxjs';
import { Const } from '@const/Const';
import { UIHelper } from '@services/UIHelper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

const CustomInputs = [
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzInputModule,
  NzIconModule,
  NzButtonModule,
  NgSwitch, NgSwitchCase,
  NgFor
]

@Component({
  selector: '[verify-login]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class VerifyLogin extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    otp: {label: 'Code:', required: true, placeHolder: 'Enter Code'}
  };
  private email = '';
  private pw = '';
  private ggToken = '';
  public onProgress: boolean = false;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.loginData) {
        try {
          const {email, pw} = JSON.parse(Crypto.decryptText(p.loginData));
          this.email = email;
          this.pw = pw;
        }
        catch (e) {
          Log.e('Parse loginData error: ', e);
          this.router.navigate([Const.routeLogin]);
        }
      }
      if (p.ggToken) {
        this.ggToken = p.ggToken;
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  get canVerify() {
    return !this.onProgress && this.needUpdate;
  }

  onOtpKeyUp(event: KeyboardEvent) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (this.canVerify) {
      this.onBtnVerify();
    }
  }

  onInputChanged(event, key: string) {
    const otpLen = 6; // FIXME: need to know OTP length dynamically
    if (key == 'otp' && this.getItemValue(key).length == otpLen) {
      return this.onBtnVerify();
    }
    super.onInputChanged(event, key);
  }

  async onBtnVerify() {
    this.onProgress = true;
    const data: any = this.getFormData_JSON(true);
    let err;
    if (this.ggToken) {
      [err] = await to(lastValueFrom(this.auth.googleAuthVerify2Fa(data.otp, this.ggToken)))
    } else {
      [err] = await to(lastValueFrom(this.auth.login(this.email, this.pw, data.otp)));
    }
    if (err) {
      UIHelper.showErr(err);
    } else {
      await this.loginSuccess();
    }
    this.onProgress = false;
  }

  private async loginSuccess() {
    await this.appComponent.loginSucceeded();
    verifyAfterLogin(this.authUser, this.router);
  }
}