import { Const } from "@const/Const";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isYesterday);


export type UsTimezone = 'Atlantic' | 'Eastern' | 'Central' | 'Mountain' | 'Pacific' | 'Alaska' | 'Hawaii' | 'Samoa';
export type StringDateTimeIso = string;

export interface TimeWindow {
  from?: StringDateTimeIso,
  to?: StringDateTimeIso,
}

interface DisplayTimeWindowOptions {
  timezone?: string,
  format?: string,                  // cả ngày và giờ
  formatDateOnly?: string,          // để xem from & to có cùng ngày không
}

export class DateUtil {
  public static Timezone_LA = 'America/Los_Angeles';

  public static get listUsTimezones(): Array<UsTimezone> {return [
    'Atlantic', 'Eastern', 'Central', 'Mountain', 'Pacific', 'Alaska', 'Hawaii', 'Samoa'
  ]}

  private static _listTimezones: Array<TimeZone> = [];
  private static _mapTimezoneByCode: {[key: string]: TimeZone} = {};

  public static set listTimezones(value: Array<TimeZone>) {
    DateUtil._listTimezones = value;
    for (let item of value) {
      this._mapTimezoneByCode[item.tzCode] = item;
    }
  }

  public static get listTimezones(): Array<TimeZone> {
    return DateUtil._listTimezones;
  }

  public static getTimezone(tzCode: string): TimeZone {
    return this._mapTimezoneByCode[tzCode];
  }

  // https://www.smarty.com/docs/cloud/us-street-api#metadata
  // Smarty's api returns US time zone in metadata field, we should map it into standard time zone used by some common date/time libraries such as dayjs, moment...
  // https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
  // Search for US/Alaska, US/Central, US/Eastern,...
  public static mapTimezoneUS(timeZoneSmarty: UsTimezone): string {
    switch (timeZoneSmarty) {
      case 'Atlantic': return 'America/Halifax';      // GMT-04:00 Canada/Atlantic
      case 'Eastern': return 'America/New_York';      // GMT-05:00
      case 'Central': return 'America/Chicago';       // GMT-06:00
      case 'Mountain': return 'America/Denver';       // GMT-07:00
      case 'Pacific': return 'America/Los_Angeles';   // GMT-08:00
      case 'Alaska': return 'America/Anchorage';      // GMT-09:00
      case 'Hawaii': return 'Pacific/Honolulu';       // GMT-10:00
      case 'Samoa': return 'Pacific/Pago_Pago';       // GMT-11:00
      default: return 'America/Los_Angeles';          // default value
    }
  }

  public static timezoneStandardToUsShort(tz: string) {
    switch (tz) {
      case 'America/Halifax': return 'AST';
      case 'America/New_York': return 'EST';
      case 'America/Chicago': return 'CST';
      case 'America/Denver': return 'MST';
      case 'America/Los_Angeles': return 'PST';
      case 'America/Anchorage': return 'AKST';
      case 'Pacific/Honolulu': return 'HAST';
      case 'Pacific/Pago_Pago': return 'SMST';
      default: return '';
    }
  }

  public static dateToString(d: Date, pattern = Const.DATETIME_FORMAT_FROM_DB) {
    return dayjs(d).format(pattern);
  }

  public static stringToDate(s: string, pattern = Const.DATETIME_FORMAT_FROM_DB): Date {
    return dayjs(s, pattern).toDate();
  }

  public static format(s: string|number|Date, pattern: string): string {
    if (!s) return '';
    let d = dayjs(s);
    return d.format(pattern);
  }

  /**
   * @param s iso date string
   * @returns {Date}
   */
  public static isoDate(s: string): Date {
    return dayjs(s).toDate();
  }

  public static isValid(d: Date): boolean {
    if (!d) return false;
    return d instanceof Date && !isNaN(d.getTime());
  }

  public static clone(d: Date): Date {
    return new Date(d.getTime());
  }

  // Hàm này chỉ chạy trên front end, vì Date ăn theo timezone của front end, nên muốn lấy timezone khác thì phải convert
  // VD chạy web ở VN nhưng giờ chọn cho timezone Mĩ thì dùng hàm này để convert 
  public static convertLocalTime(d: Date, timezoneCode: string): Date {
    if (!d) return d;
    let timezone = this.getTimezone(timezoneCode);
    if (!timezone) throw Error(`Invalid timezone ${timezoneCode}`);
    return dayjs.tz(`${dayjs(d).format('YYYY-MM-DD HH:mm:ss')}`, timezoneCode).toDate();
  }

  // Làm ngược lại với hàm convertLocalTime ở trên
  // Khi lấy dữ liệu từ server, hiển thị lên UI đúng với ngày giờ mà user đã nhập trước đó
  // VD chạy web ở VN nhưng giờ chọn cho timezone Mĩ thì cần hiển thị ra giờ Mĩ
  // Lấy dữ liệu từ server -> cho chạy qua hàm này -> rồi mới bỏ vào datepicker
  public static convertLocalTime2(isoDate: string, originTimezone: string) {
    let formated = dayjs.tz(new Date(isoDate), originTimezone).format('YYYY-MM-DD HH:mm:ss');
    return dayjs(formated).toDate();
  }

  public static getLocalTime(isoStr: string, timezone: string): Date {
    if (!isoStr) return null;
    return dayjs.tz(isoStr, timezone).toDate();
  }

  // Hàm nhận input là isoDate hoặc Date
  public static displayLocalTime(d: string | Date, timezone: string, pattern: string): string {
    if (!d) return null;
    if (typeof d === 'string') {
      return dayjs.tz(new Date(d), timezone).format(pattern);
    }
    return dayjs.tz(d, timezone).format(pattern);
  }

  // isoStr date time ISO string from database
  public static displayLocalTimeV2(isoStr, options: {timezone?: string, format?: string}) {
    if (!isoStr) return '';
    let timezone = options.timezone || 'America/Los_Angeles';
    let format = options.format || 'MMM D, YYYY, h:mm a';
    return dayjs.tz(new Date(isoStr), timezone).format(format);
  }

  /**
   * Check if d1 and d2 are different
   * d1, d2 are the date ISO string from database, with format '2022-11-23T14:35:14.000Z'
   */
  public static diff(date1: string|Date, date2: string|Date, options: {skipTime?: boolean, skipSecond?: boolean} = {}): boolean {
    let d1 = date1 instanceof Date ? date1.toISOString() : date1;
    let d2 = date2 instanceof Date ? date2.toISOString() : date2;
    if (!d1 && !d2) return false;
    if ((d1 && !d2) || (!d1 && d2)) return true;
    if (options?.skipTime) {
      // chỉ so sánh ngày, bỏ qua giờ
      let _d1 = d1.substring(0, 10);
      let _d2 = d2.substring(0, 10);
      return _d1 != _d2;
    } else {
      if (options?.skipSecond) {
        // so sánh ngày giờ nhưng chỉ lấy đến phút, bỏ qua giây
        let _d1 = d1.substring(0, 16);
        let _d2 = d2.substring(0, 16);
        return _d1 != _d2;
      }
      // so sánh đầy đủ ngày giờ
      return d1 != d2;
    }
  }

  public static diffDate(d1: string|Date, d2: string|Date): boolean {
    return DateUtil.diff(d1, d2, {skipTime: true});
  }

  // Chỉ lấy hours, minutes dưới dạng string HH:mm
  public static getHHmm(d: Date): string {
    if (typeof d === 'string') {
      return d;
    }
    if (d == null) {
      return null;
    }
    return DateUtil.dateToString(d, 'HH:mm');
  }

  /// Get value from date/time picker, handle timezone, then return object {from: ISOString, to: ISOString}
  public static toTimeWindow(date: Date | string, fromTime: Date | string, toTime: Date | string, timezone: string): TimeWindow {
    if (!date || !fromTime || !toTime) {
      return null;
    }
    //fix nếu datatype == string
    date = new Date(date)
    fromTime = new Date(fromTime);
    toTime = new Date(toTime);
    const fromTimeHHmm  = DateUtil.getHHmm(fromTime);
    const toTimeHHmm  = DateUtil.getHHmm(toTime);
    //Fixbug hàm setMonth
    fromTime.setDate(1)
    fromTime.setFullYear(date.getFullYear());
    fromTime.setMonth(date.getMonth());
    fromTime.setDate(date.getDate());
    //Fixbug hàm setMonth
    if (fromTimeHHmm <= toTimeHHmm) {
      toTime.setDate(1)
      toTime.setFullYear(date.getFullYear());
      toTime.setMonth(date.getMonth());
      toTime.setDate(date.getDate());
    } else {
      let nextDay = new Date(date);
      nextDay.setDate(date.getDate()+1);
      toTime.setDate(1)
      toTime.setFullYear(nextDay.getFullYear());
      toTime.setMonth(nextDay.getMonth());
      toTime.setDate(nextDay.getDate());
    }

    if (timezone) {
      fromTime = DateUtil.convertLocalTime(fromTime, timezone);
      toTime = DateUtil.convertLocalTime(toTime, timezone);
    }
    return {from: fromTime.toISOString(), to: toTime.toISOString()}
  }

  public static fromTimeWindow(input: {from?: string, to?: string}, timezone: string): {date?: string, fromTime?: string, toTime?: string} {
    if ((<any>input).date && (<any>input).fromTime && (<any>input).toTime) {
      return <any>input;
    }
    let obj = {};
    for (let childKey of ['from', 'to']) {
      let dt = input[childKey];
      if (dt) {
        dt = DateUtil.convertLocalTime2(dt, timezone);
        obj[childKey == 'from' ? 'fromTime' : 'toTime'] = dt.toISOString();
        if (!obj['date']) {
          obj['date'] = DateUtil.clone(dt).toISOString();
        }
      }
    }
    return obj;
  }

  public static getLocalTimeZone() {
    return dayjs.tz.guess();
  }
  public static formatDate(date, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(date).format(format);
  }

  public static getUTCTimestamp() {
    return dayjs.utc().valueOf();
  }

  public static getTimeDurationFromWindow(window: TimeWindow) {
    let timeFrom = DateUtil.isoDate(window.from).getTime();
    let timeTo = DateUtil.isoDate(window.to).getTime();
    return timeTo - timeFrom;
  }

  public static timestampToDate(timestamp: number): Date {
    return dayjs(timestamp).toDate();
  }

  public static displayTimeWindow(window: TimeWindow, options:DisplayTimeWindowOptions = {}): string {
    if (!window) return '';
    let defaultOptions: DisplayTimeWindowOptions = {format: 'MM/DD/YYYY, h:mm a', formatDateOnly: 'MM/DD/YYYY'};
    let ops: DisplayTimeWindowOptions = Object.assign(defaultOptions, options);
    if (!ops.format.startsWith(ops.formatDateOnly)) {
      throw Error('Please provide valid format and formatDateOnly');
    }
    if (!window.from) window.from = window.to;
    if (!window.to) window.to = window.from;
    let from = this.displayLocalTimeV2(window.from, ops);
    let to = this.displayLocalTimeV2(window.to, ops);
    if (from == to) {
      // Nếu trùng cả ngày giờ thì hiển thị 1 thằng thôi
      return from;
    }
    let dateFrom = this.displayLocalTimeV2(window.from, {format: ops.formatDateOnly, timezone: ops.timezone});
    let dateTo = this.displayLocalTimeV2(window.to, {format: ops.formatDateOnly, timezone: ops.timezone});
    let isSameDay = dateFrom == dateTo;
    if (isSameDay) {
      // Nếu cùng ngày khác giờ thì hiển thị dạng: ngày, giờ 1 - giờ 2
      return `${from} - ${to.substring(dateTo.length).replace(/^[^0-9]*/g, '')}`;
    } else {
      // Nếu khác ngày thì hiển thị dạng: ngày 1, giờ 1 - ngày 2, giờ 2
      return `${from} - ${to}`;
    }
  }

  public static getLocalCurrentTimeISO() {
    return DateUtil.convertLocalTime(new Date(), DateUtil.getLocalTimeZone()).toISOString();
  }

  // input duration: mins
  public static getParseDuration(duration: number) {
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;
    let day = Math.floor(hours / 24);
    if (day) return `${day} ${day > 1 ? 'days' : 'day'}`;
    if (hours) return `${hours} ${hours > 1 ? 'hours' : 'hour'} `;
    return `${minutes} ${minutes > 1 ? 'mins' : 'min'}`;
  }

  // input duration: mins
  public static getParseDurationTimeAgo(duration: number) {
    if (!duration) return 'Just now';
    let durationTxt = DateUtil.getParseDuration(duration);
    return `${durationTxt} ago`;
  }

}